import type { ModuleOptions } from '../runtime/cookies/types'

export default {
  "closeModalOnClickOutside": false,
  "cookies": {
    "necessary": [
      {
        "id": "n",
        "name": "cookies.necessary",
        "description": "cookies.necessary_description",
        "targetCookieIds": [
          "NEC"
        ]
      }
    ],
    "optional": [
      {
        "id": "an",
        "name": "cookies.analytics",
        "description": "cookies.analytics_description",
        "targetCookieIds": [
          "_ga",
          "_gat",
          "_gid"
        ]
      },
      {
        "id": "ad",
        "name": "cookies.advertising",
        "description": "cookies.advertising_description",
        "links": {
          "/privacy-policy": "cookies.optional_links.privacy_policy"
        },
        "targetCookieIds": [
          "_fbp",
          "fr",
          "tr"
        ]
      },
      {
        "id": "fu",
        "name": "cookies.functional",
        "description": "cookies.functional_description",
        "targetCookieIds": [
          "_fbc",
          "fbsr_"
        ]
      }
    ]
  },
  "cookieExpiryOffsetMs": 31536000000,
  "cookieNameIsConsentGiven": "ncc_c",
  "cookieNameCookiesEnabledIds": "ncc_e",
  "cookieOptions": {
    "path": "/",
    "readonly": false,
    "sameSite": "strict",
    "secure": true
  },
  "isAcceptNecessaryButtonEnabled": true,
  "isControlButtonEnabled": true,
  "isCookieIdVisible": false,
  "isCssEnabled": true,
  "isDashInDescriptionEnabled": true,
  "isIframeBlocked": false,
  "isModalForced": false
} as ModuleOptions