import { addAPIProvider, _api, disableCache } from "@iconify/vue";
import { defineNuxtPlugin, useAppConfig, useRuntimeConfig } from "#imports";
export default defineNuxtPlugin({
  name: "@nuxt/icon",
  setup() {
    const config = useRuntimeConfig();
    const options = useAppConfig().icon;
    _api.setFetch($fetch.native);
    disableCache("all");
    const resources = [];
    if (options.provider === "server") {
      const baseURL = config.app?.baseURL?.replace(/\/$/, "") ?? "";
      resources.push(baseURL + (options.localApiEndpoint || "/api/_nuxt_icon"));
      if (options.fallbackToApi) {
        resources.push(options.iconifyApiEndpoint);
      }
    } else {
      resources.push(options.iconifyApiEndpoint);
    }
    addAPIProvider("", { resources });
  }
});
