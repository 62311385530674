
// @ts-nocheck


export const localeCodes =  [
  "el"
]

export const localeLoaders = {
  "el": [{ key: "../../../../i18n/locales/el-GR.json", load: () => import("../../../../i18n/locales/el-GR.json" /* webpackChunkName: "locale__app_i18n_locales_el_GR_json" */), cache: true },
{ key: "../../../../i18n/locales/breadcrumb/el-GR.json", load: () => import("../../../../i18n/locales/breadcrumb/el-GR.json" /* webpackChunkName: "locale__app_i18n_locales_breadcrumb_el_GR_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../../../../i18n/i18n.config.mts?hash=1ee735a6&config=1" /* webpackChunkName: "__i18n_i18n_config_mts_1ee735a6" */)
]

export const nuxtI18nOptions = {
  "restructureDir": "i18n",
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n/i18n.config.mts",
  "locales": [
    {
      "code": "el",
      "name": "Ελληνικά",
      "files": [
        "/app/i18n/locales/el-GR.json",
        "/app/i18n/locales/breadcrumb/el-GR.json"
      ],
      "language": "el-GR",
      "flag": "🇬🇷"
    }
  ],
  "defaultLocale": "el",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": true,
    "cookieCrossOrigin": true,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": true,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "http://localhost:3000",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "el",
    "name": "Ελληνικά",
    "files": [
      {
        "path": "/app/i18n/locales/el-GR.json"
      },
      {
        "path": "/app/i18n/locales/breadcrumb/el-GR.json"
      }
    ],
    "language": "el-GR",
    "flag": "🇬🇷"
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
